import { useState, useEffect } from 'react';
import { Link as ScrollLink } from 'react-scroll';
import './Portada.css';
import images from './images.json'

function Portada() {

  //Desktop or mobile?
  const [mobile, setMobile] = useState(window.innerWidth <= 800)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 800))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 800))
  }, [])

  //ScreenSize
  const [screenSize, setScreenSize] = useState([window.innerWidth, window.innerHeight])

  useEffect(() => {
    window.addEventListener('resize', () => setScreenSize([window.innerWidth, window.innerHeight]))
    return () => window.removeEventListener('resize', () => setScreenSize([window.innerWidth, window.innerHeight]))
  }, [])

  return (
    <div className="Portada" style={mobile ? { backgroundImage: 'url(' + process.env.PUBLIC_URL + '/imgs/portada/fondo-mobile.png)' } : null}>
      <h1>PENSAR<br />LA HISTORIA</h1>
      <ScrollLink smooth to="Carousel" className="Portada-button" offset={mobile ? -72 : -82}><img src={process.env.PUBLIC_URL + '/imgs/flecha.svg'} alt="" /></ScrollLink>

      {
        !mobile ?
          images.map((image, index) => <img className='Portada-image' style={{ filter: image.filter, top: (image.top + ((screenSize[1] - 1080) / 2)) + 'px', left: (image.left + ((screenSize[0] - 1920) / 2)) + 'px' }} src={process.env.PUBLIC_URL + image.url} alt="" key={index} />)
          : null
      }

    </div>
  );
}

export default Portada;