import { useState, useEffect } from 'react';
import { Link, useLocation } from 'react-router-dom'
import './Navbar.css';

function Navbar() {

  //Desktop or mobile?
  const [mobile, setMobile] = useState(window.innerWidth <= 900)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 900))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 900))
  }, [])

  const [menuOpen, setMenuOpen] = useState(false)

  const location = useLocation().pathname

  return (
    <div className="Navbar" style={{ height: !mobile ? '82px' : !menuOpen ? '72px' : '470px' }}>
      <div className="Navbar-content">
        <Link className="Navbar-logo" to="/" onClick={() => setMenuOpen(false)} replace={location !== '/el-enfoque'}>PENSAR LA HISTORIA</Link>
        {mobile ?
          <button className="Navbar-burguer" onClick={() => setMenuOpen(prevMenuOpen => !prevMenuOpen)}>
            <div className="Navbar-burguer-topBun" style={menuOpen ? { transform: 'rotate(45deg) translate(7px, 7px)' } : null}></div>
            <div className="Navbar-burguer-burguer" style={menuOpen ? { opacity: '0' } : null}></div>
            <div className="Navbar-burguer-bottomBun" style={menuOpen ? { transform: 'rotate(-45deg) translate(6px, -6px)' } : null}></div>
          </button> : null}
        <div className="Navbar-buttons">
          <div className="Navbar-modulos">
            <Link className="Navbar-modulos-button" to="/modulos" onClick={() => setMenuOpen(false)} replace={location !== '/el-enfoque'}>UNIDADES TEMÁTICAS</Link>
            <div>
              <a href="/pensar-con-mapas">Pensar con mapas</a>
              <a href="/genero-y-nacion">Género y nación</a>
              <a href="/la-caida-de-tenochtitlan">1521 La caída de Tenochtitlan</a>
              <a href="/la-reconquista">Repensar la reconquista española</a>
              <a href="/pasados-en-conflicto">Pasados en conflicto</a>
            </div>
          </div>
          <Link className="Navbar-button" to="/el-proyecto" onClick={() => setMenuOpen(false)} replace={location !== '/el-enfoque'}>PROYECTO</Link>
          <Link className="Navbar-button" to="/el-enfoque" onClick={() => setMenuOpen(false)}>ENFOQUE</Link>
          <p className="Navbar-language">ES/<a href={"/en/" + location.substring(4, location.length) }>EN</a></p>
        </div>
      </div>
    </div>
  );
}

export default Navbar;