import './ElProyecto.css';

function ElProyecto() {

  return (
    <div className="ElProyecto">
      <div className="ElProyecto-content">
        <h3>EL PROYECTO</h3>
        <p>
          El proyecto <b>"Pensar la historia"</b> es una iniciativa de I+D sobre la enseñanza de la historia, financiado por la Fundación General de Universidad Autónoma de Madrid y la FLACSO-Argentina (Área de Psicología del Conocimiento y Aprendizaje), además de contar con los Fondos Mineco-FEDER de la Agencia Española de Investigación (RTI2018-096495-B-I00) y la ANPCYT de Argentina (PICT2019-02477). <b>El objetivo central del proyecto es contribuir a que la enseñanza de la historia genere una visión compleja y reflexiva sobre el pasado, así como una actividad de indagación por parte de los estudiantes.</b>
          <br /><br />
          El proyecto se enmarca en las actividades del grupo de investigación Pensar la historia y la memoria / Thinking histories and memories que desarrolla su producción en el contexto de la cooperación entre la <b>Universidad Autónoma de Madrid</b> y la <b>FLACSO</b> (Argentina) desde hace varias décadas. Sus investigaciones se han publicado en castellano, francés inglés y portugués, además del japonés y el euskera, y han sido pioneras en el ámbito internacional. Un espacio central en su producción vienen siendo los seminarios internacionales que el grupo organiza cada dos años en la UAM, con el apoyo de las entidades mencionadas, la AEI (España) y el programa Horizon Europa (Proyecto MakingHistories).
        </p>
      </div>
    </div>
  );
}

export default ElProyecto;