import './Footer.css';
import { useState, useEffect } from 'react';

function Footer() {

  //Desktop or mobile?
  const [mobile, setMobile] = useState(window.innerWidth <= 800)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 800))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 800))
  }, [])

  return (
    <div className="Footer">
      <div className="Footer-content">
        <div className="Footer-left">
          <a href="mailto:pensarlahistoria@flacso.org.ar" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/footer/mail.svg'} alt="Email" /> pensarlahistoria@flacso.org.ar</a>
          {!mobile ? <p>2022 Buenos Aires, Argentina</p> : null}
        </div>
        <div className="Footer-right">
          <a href="https://www.fuam.es/" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/footer/fuam.png'} alt="FUAM" /></a>
          <a href="https://www.flacso.org.ar/" target="_blank" rel="noreferrer"><img src={process.env.PUBLIC_URL + '/imgs/footer/flacso.png'} alt="FLACSO ARGENTINA" /></a>
        </div>
        {mobile ? <p>2022 Buenos Aires, Argentina</p> : null}
      </div>
    </div>
  );
}

export default Footer;