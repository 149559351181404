import './Autores.css';

function Autores() {

  const cards = [
    {
      img: process.env.PUBLIC_URL + '/imgs/autores/1.png',
      name: "Verónica Hollman",
      belonging: "(UBA-CONICET)",
      module: "Pensar con Mapas"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/autores/2.png',
      name: "Cristian Parellada",
      belonging: "(FLACSO-UNLP-CONICET)",
      module: "Pensar con Mapas"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/autores/3.png',
      name: "María Cantabrana Carassou",
      belonging: "(FLACSO-UNLP-CONICET)",
      module: "Género y Nación"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/autores/4.png',
      name: "Everardo Perez Manjarrez",
      belonging: "(UNED-HARVARD)",
      module: "1521. La caída de Tenochtitlan"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/autores/5.png',
      name: "César López Rodríguez",
      belonging: "(UAM)",
      module: "La reconquista: una narrativa histórica"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/autores/6.png',
      name: "Julio Escalona Monge",
      belonging: "(CSIC)",
      module: "La reconquista: una narrativa histórica"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/autores/7.png',
      name: "Marcelo Borrelli",
      belonging: "(CONICET)",
      module: "Pasados en conflicto"
    }
  ]

  return (
    <div className="Autores">
      <div className="Autores-content">
        <h3>AUTORES</h3>
        <div className="Autores-cards">
          {cards.map((card, index) => <div className="Autores-card" key={index}><img src={card.img} key={index} alt="" /><h6>{card.name}</h6><p className="Autores-card-belonging">{card.belonging}</p><p>{card.module}</p></div>)}
        </div>
      </div>
    </div>
  );
}

export default Autores;