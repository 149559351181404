import Portada from '../portada/Portada';
import Carousel from '../carousel/Carousel';
import QuienesSomos from '../quienes-somos/QuienesSomos'
import ElProyecto from '../el-proyecto/ElProyecto';
import Autores from '../autores/Autores'
import { useState, useEffect } from 'react';
import { scroller } from 'react-scroll'

function Home(props) {

    //Desktop or mobile?
    const [mobile, setMobile] = useState(window.innerWidth <= 900)

    useEffect(() => {
        document.title = "Pensar la historia"
        window.addEventListener('resize', () => setMobile(window.innerWidth <= 900))
        return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 900))
    }, [])

    useEffect(() => {
        if (props.to) scroller.scrollTo(props.to, { offset: mobile ? -72 : -82, smooth: true })
    }, [props.to])

    return (
        <div className="Home">
            <Portada />
            <Carousel />
            <ElProyecto />
            <QuienesSomos />
            <Autores />
        </div>
    );
}

export default Home;