import './ElEnfoque.css';
import { useState, useEffect } from 'react'

function ElEnfoque() {

  //Desktop or mobile?
  const [mobile, setMobile] = useState(window.innerWidth <= 800)

  useEffect(() => {
    document.title = "Pensar la historia | El enfoque"
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 800))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 800))
  }, [])

  useEffect(() => {
    window.scrollTo(0,0)
  }, [])

  return (
    <div className="ElEnfoque" style={{ backgroundImage: 'url(' + process.env.PUBLIC_URL + '/imgs/el-enfoque/fondo' + (mobile ? '-mobile.png)' : '.png)') }}>
      <div className="ElEnfoque-blanco">
        <div className="ElEnfoque-section-content">
          <h2 style={{ maxWidth: '660px' }}>EL PENSAMIENTO HISTÓRICO COMO NUEVOS DESAFÍOS PARA LA ENSEÑANZA SOBRE EL PASADO</h2>
          <h6>MARIO CARRETERO / MARÍA RODRÍGUEZ MONEO</h6>
          <p className="ElEnfoque-text">El proyecto “Pensar la historia” se propone generar una reflexión crítica en torno a las representaciones sobre el pasado que se construyen en el marco de la enseñanza escolar pero también -y cada vez con mayor intensidad- en las narrativas y discursos sociales que circulan a través de los medios, incluidos digitales. Se trata de favorecer una toma de distancia respecto de visiones simplistas y esencialistas que a menudo son una fuente de prejuicios y estereotipos, y que se convierten en obstáculos para el desarrollo de sociedades democráticas.</p>
          <p className="ElEnfoque-text">En esta introducción pretendemos presentar los principales enfoques actuales, en el ámbito internacional, sobre el estudio del pensamiento histórico y cómo promoverlo en el aula y en la sociedad en general.</p>
          <iframe className="ElEnfoque-video" src="https://player.vimeo.com/video/766656322?h=4aeb20e866" title="Vimeo video" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
        </div>
      </div>
      <div className="ElEnfoque-gris">
        <div className="ElEnfoque-section-content">
          <h3 style={{ maxWidth: '500px' }}>ENSEÑANZA DE LA HISTORIA Y PENSAMIENTO HISTÓRICO</h3>
          <p className="ElEnfoque-text">Las primeras investigaciones sistemáticas acerca de la educación histórica tuvieron lugar en la década de 1980 en el marco del Institute of Education de la Universidad de Londres, Reino Unido. Los trabajos de P. Lee y A. Dickinson, entre otros, estaban motivados por la idea de que a la hora de aprender historia no sólo es importante la transmisión de contenido disciplinar, sino también -o quizás, sobre todo-  el desarrollo del pensamiento histórico. Así, se sostenía que era necesario que las y los alumnos conocieran los hechos, las fechas y los lugares, pero también por qué y cómo se produjeron los acontecimientos históricos; y para ello resultaba esencial tener en cuenta la forma en que los historiadores llevan a cabo su actividad de investigación.</p>
          <iframe className="ElEnfoque-video" src="https://player.vimeo.com/video/766656865?h=dc1cced796" title="Vimeo video" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
          <p className="ElEnfoque-text">La extensa y fructífera investigación actual sobre estas cuestiones presta especial atención a cómo los estudiantes y los ciudadanos en general entienden los conceptos, el tiempo, la causalidad histórica y otros aspectos relacionados con el conocimiento histórico. En primer lugar, podemos destacar los trabajos de Sam Wineburg, de la Universidad de Stanford (EEUU), que enfatiza la enseñanza de métodos historiográficos, adaptados didácticamente al nivel de los alumnos, en lugar de centrarse en la simple memorización rutinaria de los contenidos históricos. Esta propuesta se ha basado en la existencia de tres heurísticos o procedimientos que se usan en la solución de problemas históricos: la corroboración, esto es, el acto de comparar documentos entre sí; la comprobación del origen de las fuentes, y la  contextualización o la actividad de situar un documento en un contexto temporal y espacial concreto en el que fue producido. Estas y otras ideas relacionadas generaron el desarrollo de programas educativos que han tenido un gran impacto en las escuelas de Estados Unidos y de otros países (<a href="https://sheg.stanford.edu/history-lessons" target="_blank" rel="noreferrer">https://sheg.stanford.edu/history-lessons</a>).</p>
          <p className="ElEnfoque-text">Las referencias al pensamiento histórico también aparecen en las iniciativas lideradas por Peter Seixas en Canadá, en torno a la idea de la conciencia histórica que presenta la importante influencia de autores alemanes como J. Rüsen. En esta perspectiva, la historia debería enseñarse no sólo para proporcionar una comprensión del pasado sino también para generar una posición consciente, entre las y los alumnos y ciudadanos en general, de los problemas y futuros posibles de las sociedades contemporáneas. Este programa educativo (<a href="https://historicalthinking.ca/" target="_blank" rel="noreferrer">https://historicalthinking.ca/</a>) ha desarrollado seis conceptos esenciales que suelen denominarse “meta-conceptos” o “conceptos de segundo orden" que subyacen a los de primer orden -aquellos referido a procesos históricos específicos tales como feudalismo, imperialismo o neolítico- y que son necesarios para desarrollar el pensamiento histórico. Estos son:  a) significación del problema histórico en cuestión, b) evidencia a través de fuentes primarias o de otro tipo, c) continuidad y cambio del proceso histórico, d) causas y consecuencias, e) perspectivas históricas teóricas relacionadas con la interpretación y f) dimensiones éticas del pasado.</p>
          <iframe className="ElEnfoque-video" src="https://player.vimeo.com/video/766657198?h=16e349132b" title="Vimeo video" frameBorder="0" allow="autoplay; fullscreen; picture-in-picture"></iframe>
        </div>
      </div>
      <div className="ElEnfoque-blanco">
        <div className="ElEnfoque-section-content">
          <h3 style={{ maxWidth: '480px' }}>PENSAR HISTÓRICAMENTE A TRAVÉS DEL DIÁLOGO</h3>
          <p className="ElEnfoque-text">En el contexto europeo, autoras como Carla Van Boxtel y Janet Van Drie han desarrollado una fructífera iniciativa de enseñanza a través de un marco dialógico. Este trabajo deriva de las contribuciones mencionadas anteriormente acerca del fomento del pensamiento histórico, y también hace hincapié en el uso de documentos y pruebas. Sin embargo, basándose en las ideas vigotskianas sobre la naturaleza e importancia del diálogo, afirman que la pericia histórica no se basa solo en las operaciones cognitivas individuales. Siguiendo esta idea, enfrentan a los estudiantes en las aulas con una serie de actividades dialógicas en las que tienen que comparar y evaluar diferentes puntos de vista sobre un mismo tema histórico. Más específicamente, estas actividades proponen hacer preguntas históricas, conectar eventos, desarrollos y acciones a través de una contextualización histórica, usar conceptos históricos sustantivos (hechos, conceptos y cronología), conceptos históricos de segundo orden y apoyar las afirmaciones con argumentos y pruebas basados en fuentes previamente evaluadas por ellos mismos.</p>
        </div>
      </div>
      <div className="ElEnfoque-gris">
        <div className="ElEnfoque-section-content">
          <h3 style={{ maxWidth: '680px' }}>IDEAS PREVIAS Y CAMBIO CONCEPTUAL EN EL CONOCIMIENTO HISTÓRICO</h3>
          <p className="ElEnfoque-text">El trabajo del equipo a cargo del diseño del proyecto “Pensar la historia”, por su parte, ha desarrollado la importancia del enfoque de cambio conceptual en el pensamiento histórico. Treinta años de estudios cognitivos sobre la adquisición de conocimientos y el aprendizaje han demostrado la importancia de considerar las representaciones previas de los estudiantes en las propuestas de enseñanza. Las mentes de las y los estudiantes y ciudadanos en general no son una tabula rasa cuando se enfrentan a problemas históricos en contextos formales o informales. Por el contrario, están muy influidos por sus concepciones preexistentes, que podrán ser revisadas o no, dependiendo de la calidad de la enseñanza que reciban.</p>
          <p className="ElEnfoque-text">Estas investigaciones han revelado que el conocimiento previo, a menudo cercano a los prejuicios y estereotipos, juega un papel esencial en el proceso de aprendizaje y es crucial entender plenamente cómo los conceptos históricos pueden transformarse en representaciones más complejas y disciplinarias del pasado. Muchos conceptos históricos de estudiantes y ciudadanos provienen de narrativas maestras nacionales, que consisten en representaciones idealizadas y también, a menudo, muy anecdóticas y simplistas. Es importante tener en cuenta que al menos la mitad de los contenidos históricos de cualquier plan de estudios están centrados en la historia nacional y el contexto más global y planetario suele no tenerse en cuenta. Por lo tanto, un modelo de pensamiento histórico debe incluir formas de facilitar un aprendizaje complejo de las narrativas maestras, que ayude a una reflexión crítica sobre las mismas. Por ejemplo, como puede verse en el módulo sobre la reconquista española se suele considerar, tanto en la escuela como fuera de ella, que ésta supuso la “fundación” de España como nación, posición que ha sido claramente cuestionada por la propia historiografía.</p>
        </div>
      </div>
      <div className="ElEnfoque-blanco">
        <div className="ElEnfoque-section-content">
          <h3 style={{ maxWidth: '670px' }}>HISTORIA NACIONAL VERSUS HISTORIA GLOBAL</h3>
          <p className="ElEnfoque-text">Numerosos autores han discutido qué historia enseñar y por qué este es un debate central en tiempos tan desafiantes como los actuales, en los que es preciso admitir el reconocimiento de la pérdida de poder político que están presenciando los estados-nación actuales en el contexto de globalización. Asimismo, nuevos agentes políticos de orígenes étnicos, religiosos y culturales diversos compiten entre sí para ser reconocidos como sujetos nacionales emergentes que reclaman derechos históricos basados en su memoria colectiva. Este es el caso de los afroamericanos en Estados Unidos, de los norteafricanos en Francia y de la población musulmana en toda Europa, así como obviamente las mujeres, cuyo papel en la historia ha sido reformulado por las diferentes perspectivas actuales sobre los problemas de género. Algunos programas educativos actuales muestran claramente esta tendencia (<a href="https://choices.edu" target="_blank" rel="noreferrer">www.choices.edu</a> y <a href="https://www.zinnedproject.edu" target="_blank" rel="noreferrer">www.zinnedproject.edu</a>). Por último, la migración se cuenta entre los factores que producen inestabilidad política e ideológica en los actuales estados nacionales. Estos factores dibujan un escenario complejo para la enseñanza de la historia, teniendo en cuenta que el objetivo tradicional de esta asignatura ha sido precisamente contribuir de manera significativa a la construcción de las identidades nacionales tradicionales a través de narrativas históricas maestras.</p>
          <p className="ElEnfoque-text">En este sentido, es preciso recordar que tradicionalmente en la mayoría de los países se reconocen dos tipos de objetivos en la enseñanza de la historia que hemos definido como románticos e ilustrados, porque sus características y funciones se derivan de sus respectivas raíces intelectuales en el Romanticismo y la Ilustración. En otras palabras, se supone que la historia se ha enseñado en todos los sistemas escolares nacionales atendiendo a dos objetivos diferentes y hasta cierto punto contradictorios: hacer que los estudiantes "amen a su país" y hacerlos "entender su pasado” para tener una conciencia histórica. En una vena romántica, la educación histórica es una estrategia fundamental utilizada para lograr: a) una evaluación positiva del pasado, presente y futuro del propio grupo social, tanto local como nacional, y b) una identificación con la historia política del país. Por el contrario, en un sentido ilustrado se trata de promover el fomento de ciudadanos críticos capaces de una participación informada y efectiva en los cambios históricos tanto de la nación como del resto del mundo. Esto debería incluir la crítica de la propia comunidad local, nacional o de unidades políticas más grandes, como la Unión Europea, América Latina o, incluso, el planeta en tanto somos ciudadanos y ciudadanas de naciones diferentes pero también miembros de la especie humana.</p>
        </div>
      </div>
      <div className="ElEnfoque-gris">
        <div className="ElEnfoque-section-content">
          <h3 style={{ maxWidth: '630px' }}>DESAFÍOS PARA UNA EDUCACIÓN HISTÓRICA CON SENTIDO DE FUTURO</h3>
          <p className="ElEnfoque-text">Teniendo en cuenta lo expuesto anteriormente y a la luz de la investigación actual en el contexto internacional, podríamos decir que los desafíos más importantes para la investigación y las aplicaciones en este campo -y que buscamos desarrollar en el proyecto “Pensar la historia”- son los siguientes:</p>
          <p className="ElEnfoque-text">a) La necesidad de producir un modelo integrado de pensamiento y conciencia histórica, incluyendo los avances actuales en este campo, pero también los nuevos desarrollos, particularmente en un sentido dialógico y multi-perspectivista.</p>
          <p className="ElEnfoque-text">b) La importancia de ir más allá de las historias nacionales en la enseñanza de la historia, incorporando temas socialmente relevantes para el planeta en su conjunto que afectan a toda la especie humana, como la historia de género, la globalización y las migraciones.</p>
          <p className="ElEnfoque-text">c) La relevancia de las herramientas críticas para que los estudiantes y los ciudadanos establezcan relaciones relevantes entre el pasado y el presente, y para superar las visiones esencialistas y simplistas del pasado.</p>
          <p className="ElEnfoque-text">d) La necesidad de una integración significativa y crítica de los medios digitales que reconozca las transformaciones de la sociedad contemporánea y el potencial de las tecnologías para el aprendizaje y la construcción del pensamiento histórico.</p>
        </div>
      </div>
      <div className="ElEnfoque-blanco">
        <div className="ElEnfoque-section-content">
          <h3 style={{ maxWidth: '670px' }}>REFERENCIAS BIBLIOGRÁFICAS</h3>
          <p className="ElEnfoque-text">Carretero, M. (Ed.) (en prensa) <i>La enseñanza de la historia en la era digital.</i> Tilde Editora.</p>
          <p className="ElEnfoque-text">Carretero, M. y Castorina, J.A. (comps.) (2022) <i>La construcción del conocimiento histórico. Enseñanza, narración e identidades.</i> Tilde Editora.<br /><a className="ElEnfoque-text-reference" href="https://tildeeditora.publica.la/reader/recurso-la-construccion-de-conocimiento-historico-ensenanza-narracion-e-identidades" target="_blank" rel="noreferrer">Versión digital</a><br /><a className="ElEnfoque-text-reference" href="https://open.spotify.com/episode/5SLtb9m45tZQTGulHNBZcW?si=846fc87fc34e49bc" target="_blank" rel="noreferrer">Podcast</a></p>
          <p className="ElEnfoque-text">Carretero, M. (2021) <i>Documentos de identidad.</i> Edición revisada. Tilde Editora.<br /><a className="ElEnfoque-text-reference" href="https://d29ca094-8776-4173-96db-313342ed697d.usrfiles.com/ugd/d29ca0_a949c0e37be24ca9aa04bac56cc27653.pdf" target="_blank" rel="noreferrer">Versión digital</a></p>
          <p className="ElEnfoque-text">Carretero, M., Berger, S. y Grever, M. (Eds.) (2017) <i>Palgrave Handbook of Research in Historical Culture and Education.</i> Palgrave.</p>
          <p className="ElEnfoque-text">Carretero, M., Asensio, M. y Rodriguez-Moneo, M. (Eds.) (2011) <i>History education and the construction of national identities.</i> Information Age Publishers.</p>
        </div>
      </div>
    </div>
  );
}

export default ElEnfoque;