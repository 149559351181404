import './App.css';
import Navbar from './components/navbar/Navbar';
import Home from './components/home/Home'
import ElEnfoque from './components/el-enfoque/ElEnfoque'
import Footer from './components/footer/Footer';
import { Routes, Route, Navigate } from 'react-router-dom'

function App() {
  return (
    <div className="App">
      <Navbar />

      <Routes>
        <Route exact path="/" element={<Home to="Portada" />} />
        <Route path="/modulos" element={<Home to="Carousel" />} />
        <Route path="/el-proyecto" element={<Home to="ElProyecto" />} />
        <Route path="/quienes-somos" element={<Home to="QuienesSomos" />} />
        <Route path="/contacto" element={<Home to="Footer" />} />
        <Route path="/el-enfoque" element={<ElEnfoque />} />

        <Route path="/*" element={<Navigate to="/" replace />} />
      </Routes>

      <Footer />
    </div>
  );
}

export default App;