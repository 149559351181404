import './QuienesSomos.css';

function QuienesSomos() {

  const cards = [
    {
      img: process.env.PUBLIC_URL + '/imgs/quienes-somos/1.png',
      name: "Mario Carretero",
      belonging: "(UAM-FLACSO)",
      role: "Dirección general"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/quienes-somos/2.png',
      name: "Angeles Soletic",
      belonging: "(UBA-CONICET)",
      role: "Coordinación de proyecto"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/quienes-somos/3.png',
      name: "Rocío Ivanissevich",
      belonging: "(UNCo)",
      role: "Coordinación autoral"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/quienes-somos/4.png',
      name: "Julieta Vela",
      belonging: "(FLACSO)",
      role: "Dirección de arte"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/quienes-somos/5.png',
      name: "Germán Belzunce",
      role: "Diseño UX"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/quienes-somos/6.png',
      name: "Alejo Torres",
      role: "Desarrollo Web"
    },
    {
      img: process.env.PUBLIC_URL + '/imgs/quienes-somos/7.png',
      name: "Federico Dorfman",
      belonging: "(FLACSO)",
      role: "Gestión y apoyo técnico"
    }
  ]

  return (
    <div className="QuienesSomos">
      <div className="QuienesSomos-content">
        <h3>QUIÉNES SOMOS</h3>
        <div className="QuienesSomos-cards">
          {cards.map((card, index) => <div className="QuienesSomos-card" key={index}><img src={card.img} key={index} alt="" /><h6>{card.name}</h6><p className="QuienesSomos-card-belonging">{card.belonging}</p><p>{card.role}</p></div>)}
        </div>
      </div>
    </div>
  );
}

export default QuienesSomos;