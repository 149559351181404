import { useState, useEffect } from 'react'
import './Carousel.css'

function Carousel() {

  //Desktop or mobile?
  const [mobile, setMobile] = useState(window.innerWidth <= 800)

  useEffect(() => {
    window.addEventListener('resize', () => setMobile(window.innerWidth <= 800))
    return () => window.removeEventListener('resize', () => setMobile(window.innerWidth <= 800))
  }, [])

  const [selectedElement, setSelectedElement] = useState(0)
  const showPrevElement = () => setSelectedElement(prevSelectedElement => prevSelectedElement === 0 ? prevSelectedElement : prevSelectedElement - 1)
  const showNextElement = () => setSelectedElement(prevSelectedElement => prevSelectedElement === elements.length - 1 ? prevSelectedElement : prevSelectedElement + 1)

  const [touchPosition, setTouchPosition] = useState(null)
  const handleTouchStart = e => {
    const touchDown = e.touches[0].clientX
    setTouchPosition(touchDown)
  }
  const handleTouchMove = e => {
    const touchDown = touchPosition
    if (touchDown === null) {
      return
    }
    const currentTouch = e.touches[0].clientX
    const diff = touchDown - currentTouch
    if (diff > 15) {
      showNextElement()
    }
    if (diff < -15) {
      showPrevElement()
    }
    setTouchPosition(null)
  }

  const [myTimeout, setMyTimeout] = useState()

  const elements = [{
    background: process.env.PUBLIC_URL + (mobile ? "/imgs/carousel/1-mobile.png" : "/imgs/carousel/1.png"),
    title: <h3 style={selectedElement === 0 ? {} : { opacity: '0', transform: 'translateX(20vw)' }}>PENSAR<br />CON MAPAS</h3>,
    text: "¿Los mapas “retratan” los fenómenos territoriales o presentan un punto de vista sobre ellos? ¿Puede un mapa mostrar todo simultáneamente?¿Qué decisiones se toman al hacer un mapa? ¿Es posible rastrear cómo intervienen los conocimientos, las ideologías y las tecnologías en la producción de los mapas? ¿Para qué se han usado y se usan los mapas?",
    link: "/pensar-con-mapas",
    disabled: false
  },
  {
    background: process.env.PUBLIC_URL + (mobile ? "/imgs/carousel/2-mobile.png" : "/imgs/carousel/2.png"),
    title: <h3 style={selectedElement === 1 ? {} : { opacity: '0', transform: 'translateX(20vw)' }}>Género<br />y Nación</h3>,
    text: "Del Ni una Menos al #MeToo, pasando por las huelgas de mujeres, las multitudinarias marchas de cada 8 de marzo o las luchas por el aborto legal, la plena actualidad del feminismo señala que en nuestras sociedades la desigualdad de género es una realidad en muchos ámbitos. Esta desigualdad tiene una larga historia; es fruto de complejos procesos que, probablemente, están presentes desde el origen de la historia.",
    link: "/genero-y-nacion",
    disabled: false
  },
  {
    background: process.env.PUBLIC_URL + (mobile ? "/imgs/carousel/3-mobile.png" : "/imgs/carousel/3.png"),
    title: <div className="Carousel-element-2-title" style={selectedElement === 2 ? {} : { opacity: '0', transform: 'translateX(20vw)' }}><h3>1521</h3><h4>LA CAÍDA DE<br /><b>TENOCHTITLAN</b></h4></div>,
    text: "La caída de Tenochtitlan, en 1521, suele conocerse como el evento que marca la llamada “conquista de México”. Pero, ¿efectivamente cayó “México”? Y si es así, ¿quién lo conquistó?, ¿quiénes fueron los vencedores y quiénes los vencidos? Investigaciones recientes han reformulado estas y muchas otras preguntas, proponiendo nuevas miradas sobre este complejo y dramático proceso histórico. En este módulo nos enfocaremos en los principales acontecimientos que tuvieron lugar entre 1511 y 1521 en Mesoamérica, para examinar los mitos de la narrativa tradicional de la conquista de América y explorar posibles nuevas interpretaciones de este hecho fundamental de la historia moderna mundial. Además, analizaremos las controversias que despierta hoy este proceso, 500 años después.",
    link: "/la-caida-de-tenochtitlan",
    disabled: false
  },
  {
    background: process.env.PUBLIC_URL + (mobile ? "/imgs/carousel/4-mobile.png" : "/imgs/carousel/4.png"),
    title: <h3 style={selectedElement === 3 ? {} : { opacity: '0', transform: 'translateX(20vw)' }}>REPENSAR LA<br />RECONQUISTA<br />ESPAÑOLA</h3>,
    text: "La reconquista es uno de los grandes relatos de la historia de España. Ha sido tan importante que gracias a esta narrativa personajes como Pelayo, el Cid o los Reyes Católicos se convirtieron en los heroicos protagonistas de una gesta que es la de toda la nación española. Al igual que otras narrativas nacionales, el relato de la reconquista —creado mucho tiempo después que el proceso histórico que describe— mitificó el pasado y lo entretejió con la identidad presente, buscando componer una imagen nacional positiva que sirviera de aglutinante para la ciudadanía, sin importar el rigor histórico. A través del análisis de la idea de reconquista, a continuación analizaremos los principales elementos que componen las narrativas nacionales y trataremos de entender por qué son tan relevantes para el presente.",
    link: "/la-reconquista",
    disabled: false
  },
  {
    background: process.env.PUBLIC_URL + (mobile ? "/imgs/carousel/5-mobile.png" : "/imgs/carousel/5.png"),
    title: <img src={process.env.PUBLIC_URL + '/imgs/carousel/pasados-en-conflicto.svg'} alt="Pasados en conflicto" />,
    text: "Los acontecimientos históricos traumáticos, como los genocidios, las guerras civiles o los conflictos políticos violentos ocurridos en tiempos no tan lejanos dejan marcas indelebles en la vida de las personas y las sociedades que han atravesado esos sucesos. Son pasados que siguen presentes. ¿Por qué ocurrieron? ¿Quiénes fueron los responsables? ¿Cómo reparar a las víctimas? En este módulo presentamos dilemas y debates surgidos en torno a esos pasados violentos y las maneras en que diversas sociedades han tratado de abordarlos.",
    link: "/pasados-en-conflicto",
    disabled: false
  }]

  useEffect(() => {
    clearTimeout(myTimeout)
    setMyTimeout(setTimeout(() => setSelectedElement(prevSelectedElement => prevSelectedElement === elements.length - 1 ? 0 : prevSelectedElement + 1), 15000))
  }, [selectedElement])

  return (
    <div className="Carousel" onTouchStart={handleTouchStart} onTouchMove={handleTouchMove}>
      <div className="Carousel-elements" style={{ marginLeft: 'calc(' + selectedElement * -1 + ' * 100%)', width: 'calc(' + elements.length + ' * 100%)' }}>
        {elements.map((element, index) =>
          <div className={"Carousel-element Carousel-element-" + index} key={index} style={{ backgroundImage: 'url(' + element.background + ')' }}>
            <div className="Carousel-element-content">
              {element.title}
              <div className="Carousel-element-text" style={selectedElement === index || element.disabled ? {} : { opacity: '0', transform: 'translateX(20vw)' }}><p>{element.text}</p></div>
              <a style={selectedElement === index && !element.disabled ? {} : { opacity: '0' }} href={element.link} target="_blank" rel="noreferrer">ENTRAR</a>

              {element.disabled ? <div className="Carousel-element-disabled"></div> : null}
            </div>
          </div>
        )}
      </div>

      <button className={"Carousel-arrow-left" + (selectedElement === 0 ? " Carousel-arrow-disabled" : "")} onClick={showPrevElement}><img src={process.env.PUBLIC_URL + '/imgs/carousel/flecha.svg'} alt="Anterior" /></button>
      <button className={"Carousel-arrow-right" + (selectedElement === elements.length - 1 ? " Carousel-arrow-disabled" : "")} onClick={showNextElement}><img src={process.env.PUBLIC_URL + '/imgs/carousel/flecha.svg'} alt="Siguiente" /></button>

      <div className="Carousel-buttons" style={{ left: 'calc(50% - (' + elements.length * 24 + 'px / 2)' }}>
        {elements.map((element, index) => <button className={"Carousel-button" + (selectedElement === index ? " Carousel-button-selected" : "")} onClick={() => setSelectedElement(index)} key={index}></button>)}
      </div>
    </div>
  );
}

export default Carousel;